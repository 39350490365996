import { CaseStudiesHero, PostsGrid } from '@components/Blog'
import Head from '@components/Head'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

const CaseStudyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      posts: allWpCaseStudy {
        nodes {
          title
          excerpt
          id
          slug
          date(formatString: "dddd, MMMM D YYYY")
          acf {
            clientName
            clientLogo {
              sourceUrl
            }
            industry
          }
        }
      }
    }
  `)
  return (
    <>
      <Head
        title="Case Studies"
        description="MRS client case studies for data analytics, SharePoint, Office 365, cloud solutions, workflow automation, enterprise content management, intranet portals and more."
      />
      <CaseStudiesHero />
      <PostsGrid data={data} type="case-studies" />
    </>
  )
}
export default CaseStudyPage
